
declare let LiveAgent;
export function loadChatScript(chatType, email, firstName) {
  let buttonId = '';
  if (chatType.includes('vip')) {
    switch (chatType) {
      case 'vip-en':
        buttonId = 'mrfynz63';
        break;
      case 'vip-de':
        buttonId = 'mrfynz63';
        break;
      case 'vip-pl':
        buttonId = 'm87w5x63';
        break;
      case 'vip-de-AT':
        buttonId = 'mrfynz63';
        break;
      case 'vip-hu':
        buttonId = 'mrfynz63';
        break;
      case 'vip-en-IN':
        buttonId = 'mrfynz63';
        break;
      default:
        buttonId = 'mrfynz63';
        break;
    }
  } else {
    switch (chatType) {
      case 'en':
        buttonId = 'jbrk30zv';
        break;
      case 'de':
        buttonId = 'zca9ju2f';
        break;
      case 'pl':
        buttonId = 'm87w5x63';
        break;
      case 'de-AT':
        buttonId = 'i9f1yi63';
        break;
      case 'hu':
        buttonId = 'xu2f4mf8';
        break;
      case 'en-IN':
        buttonId = 'qmju87b2';
        break;
      default:
        buttonId = 'jbrk30zv';
        break;
    }
  }

  const current = document.querySelectorAll('.circleRollButton');

  if (current.length === 0) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
    (function(d, src, c) {
      var t = d.scripts[d.scripts.length - 1],
        s = d.createElement('script');
      s.id = 'la_x2s6df8d';
      s.defer = true;
      s.src = src;
      s.onload = s.onreadystatechange = function() {
        var rs = this.readyState;
        if (rs && (rs != 'complete') && (rs != 'loaded')) {
          return;
        }
        c(this);
      };
      t.parentElement.insertBefore(s, t.nextSibling);
    })(document, 'https://playbison.ladesk.com/scripts/track.js', function(e) {
      LiveAgent.createButton('${buttonId}', e);
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails('${email}', '${firstName}');
    });
  `;
    try {
      LiveAgent
      showChat();
    } catch (e) {
      body.appendChild(script);
    }
  }
}

export function hideChat() {
  try {
    document.querySelectorAll('.circleRollButton').forEach(item => {
      item.classList.add("hide");
    });
  } catch (e) {

  }
}

export function showChat() {
  try {
    document.querySelectorAll('.circleRollButton').forEach(item => {
      item.classList.remove("hide");
    });
  } catch (e) {

  }

}
